<template>
  <v-img src="@/assets/image/png/loginPage.png" height="100vh">
    <div style="height: 10%; text-align: center" />

    <div style="position: relative; height: 15%">
      <img
        src="@/assets/image/png/logo/new_logo_img.png"
        width="200"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      />
    </div>

    <div style="height: 50%" class="loginList">
      <!-- <div v-for="notice in noticeList" :key="notice.id">
        <div class="leftListBody">
          <div class="leftListBodyTit">{{ notice.date }}</div>
          <div class="leftListBodyCon">
            <div class="listTit">{{ notice.category }}</div>
            <div>{{ notice.content }}</div>
          </div>
        </div>
      </div> -->
      <div class="loginList">
        <div class="leftListBody">
          <div class="leftListBodyTit">2025.01.21</div>
          <div class="leftListBodyCon">
            <div class="listTit">공지사항</div>
            <div>생산페이지 디자인 리뉴얼</div>
          </div>
        </div>

        <div class="leftListBody">
          <div class="leftListBodyTit">2025-01-21</div>
          <div class="leftListBodyCon">
            <div class="listTit">공지사항</div>
            <div>생산페이지 생지의뢰 기능 추가</div>
          </div>
        </div>

        <div class="leftListBody">
          <div class="leftListBodyTit">2025-01-21</div>
          <div class="leftListBodyCon">
            <div class="listTit">공지사항</div>
            <div>가공수량 Kg 추가</div>
          </div>
        </div>

        <div class="leftListBody">
          <div class="leftListBodyTit">2025-01-21</div>
          <div class="leftListBodyCon">
            <div class="listTit">공지사항</div>
            <div>재고페이지 구분 추가</div>
          </div>
        </div>

        <div class="leftListBody">
          <div class="leftListBodyTit">2025-01-21</div>
          <div class="leftListBodyCon">
            <div class="listTit">공지사항</div>
            <div>기초아이템 등록 디자인 및 항목 수정</div>
          </div>
        </div>
        <div class="leftListBody">
          <div class="leftListBodyTit">2025-01-13</div>
          <div class="leftListBodyCon">
            <div class="listTit">공지사항</div>
            <div>폰트 크기설정 추가</div>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 20%; text-align: center" class="pt-12">
      <div class="leftListBottomTop">Welcome to WondanMania</div>
      <div class="leftListBottomBottom">
        원단 마니아 홈페이지에 오신것을 환영합니다.<br />
        고객님의 의견에 귀를 기울이겠습니다. 감사합니다.
      </div>
    </div>
  </v-img>
</template>

<script>
export default {
  data() {
    return {
      noticeList: [
        {
          id: 1,
          date: "2025.01.21",
          category: "공지사항",
          content: "생산페이지 디자인 리뉴얼",
        },
        {
          id: 2,
          date: "2025.01.21",
          category: "공지사항",
          content: "생산페이지 생지의뢰 기능 추가",
        },
        {
          id: 3,
          date: "2025.01.21",
          category: "공지사항",
          content: "가공수량 Kg 추가",
        },
        {
          id: 4,
          date: "2025.01.21",
          category: "공지사항",
          content: "재고페이지 구분 추가",
        },
        {
          id: 5,
          date: "2025.01.21",
          category: "공지사항",
          content: "기초아이템 등록 디자인 및 항목 수정",
        },
        {
          id: 6,
          date: "2025.01.13",
          category: "공지사항",
          content: "폰트 크기설정 추가",
        },
      ],
    };
  },
};
</script>
